<template>
  <div
    class="h-screen flex w-full bg-primary vx-row no-gutter items-center justify-center"
    id="page-login"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body">
          <div
            class="vx-row no-gutter justify-center items-center p-10"
            v-if="error == 'none'"
          >
            <!-- Logo -->
            <div class="w-full justify-center">
              <div class="vx-row no-gutter justify-center items-center">
                <img
                  src="http://emops.com.br/world/relatorio_novo/img/logo-grupo.png"
                  alt="login"
                  width="100"
                  class="mx-auto"
                />
              </div>
            </div>

            <div class="vx-col w-full mt-3 mb-2 w-full text-center">
              <h4>CONCLUSÃO DE CADASTRO</h4>
            </div>

            <form-wizard
              @on-complete="update"
              color="rgba(var(--vs-dark), 1)"
              errorColor="rgba(var(--vs-danger), 1)"
              :title="null"
              :subtitle="null"
              finishButtonText="Atualizar dados"
            >
              <tab-content
                title="Passo 1"
                class="mb-5"
                icon="feather icon-home"
              >
                <form data-vv-scope="step-1">
                  <div class="vx-row">
                    <div class="vx-col md:w-1/2 w-full mt-5">
                      <vs-input
                        label="Nome completo"
                        v-model="colaborador.nome"
                        class="w-full"
                        name="nome"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('nome')"
                        >{{ errors.first("nome") }}</span
                      >
                    </div>
                    <div class="vx-col md:w-1/2 w-full mt-5">
                      <vs-input
                        label="CPF"
                        v-model="colaborador.cpf"
                        class="w-full"
                        name="cpf"
                      />
                      <span class="text-danger"></span>
                    </div>
                    <div class="vx-col md:w-1/2 w-full mt-5">
                      <vs-input
                        label="RG"
                        v-model="colaborador.rg"
                        class="w-full"
                        name="rg"
                      />
                      <span class="text-danger"></span>
                    </div>
                    <div class="vx-col md:w-1/2 w-full mt-5">
                      <vs-input
                        label="CNH (opcional)"
                        v-model="colaborador.cnh"
                        class="w-full"
                        name="cnh"
                      />
                      <span class="text-danger"></span>
                    </div>
                  </div>
                </form>
              </tab-content>

              <tab-content
                title="Passo 2"
                class="mb-5"
                icon="feather icon-phone"
              >
                <form data-vv-scope="step-2">
                  <div class="vx-row w-full">
                    <div class="vx-col md:w-1/2 w-full mt-5">
                      <vs-input
                        label="Celular / Telefone"
                        v-model="colaborador.telefone"
                        class="w-full"
                        name="telefone"
                      />
                      <span class="text-danger"></span>
                    </div>
                    <div class="vx-col md:w-1/2 w-full mt-5">
                      <vs-input
                        label="Data nascimento"
                        type="date"
                        v-model="colaborador.data_nascimento"
                        class="w-full"
                        name="data_nascimento"
                      />
                      <span class="text-danger"></span>
                    </div>
                    <div class="vx-col w-full mt-5">
                      <vs-input
                        label="Endereco"
                        v-model="colaborador.endereco"
                        class="w-full"
                        name="endereco"
                      />
                      <span class="text-danger"></span>
                    </div>
                  </div>
                </form>
              </tab-content>

              <tab-content
                title="Passo 3"
                class="mb-5"
                icon="feather icon-lock"
              >
                <form data-vv-scope="step-3">
                  <div class="vx-row">
                    <div class="vx-col w-full mt-5">
                      <vs-input
                        label="Senha"
                        v-model="colaborador.password"
                        type="password"
                        class="w-full"
                        name="password"
                      />
                      <span class="text-danger"></span>
                    </div>
                    <div class="vx-col w-full mt-5">
                      <vs-input
                        label="Repita a senha"
                        type="password"
                        v-model="colaborador.passwordConfirm"
                        class="w-full"
                        name="password_confirm"
                      />
                      <span class="text-danger"></span>
                    </div>
                  </div>
                </form>
              </tab-content>
            </form-wizard>
          </div>
          <div
            class="vx-row no-gutter text-center items-center p-10"
            v-if="error != 'none'"
          >
            <h2 class="w-full">{{ error }}</h2>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard"
import "vue-form-wizard/dist/vue-form-wizard.min.css"
import HOSTS from "../../../enviroments"

export default {
  data () {
    return {
      colaborador: {
        nome: "",
        cpf: "",
        rg: "",
        cnh: "",
        telefone: "",
        endereco: "",
        password: "",
        passwordConfirm: "",
        data_nascimento: ""
      },
      error: "",
      app_host: `${process.env.VUE_APP_HOST}`,
    }
  },
  components: {
    FormWizard,
    TabContent
  },
  methods: {
    async update () {
      let verify = await this.verifyPassword()
      if (verify) {
        let colObject = this.colaborador
        delete colObject.passwordConfirm
        await this.$vs.loading()
        try {
          const res = await this.$http.post(`updatePrimeiroAcesso`, { colaborador: colObject, app_host: this.app_host })
          if (res.hasOwnProperty("id")) {
            await this.$vs.notify({
              time: 5000,
              title: "Sucesso",
              text: "Seus dados foram atualizados. Redirecionando para a tela de login...",
              color: "success"
            })
            window.location = await HOSTS.HOST
            setTimeout(() => { }, 1000)
          } else {
            this.$vs.notify(this.$notify.Error)
          }
        } catch (err) {
          const error = await this.$httpErrors(err)
          this.$vs.notify(error)
          this.$vs.loading.close()
        }
      }
    },
    getUser () {
      const url = window.location.href
      const token = url.split("acesso/").pop()
      return { token: token, id_empresa: 1 }
    },
    async getInitialInfo () {
      try {
        const res = await this.$http.post("validate", this.getUser())
        if (res.hasOwnProperty("id")) {
          this.colaborador.nome = res.nome
          this.colaborador.email = res.email
          this.colaborador.id = res.id
          this.error = await "none"
        }
      } catch (err) {
        this.error = await "TOKEN INVALIDO"
      }
    },
    async verifyPassword () {
      if (this.colaborador.password.length == 0) {
        await this.$vs.notify({
          time: 2000,
          title: "",
          text: "As senhas nao podem ser vazias",
          color: "danger"
        })
      } else if (this.colaborador.password != this.colaborador.passwordConfirm) {
        await this.$vs.notify({
          time: 2000,
          title: "",
          text: "As senhas nao concedem",
          color: "danger"
        })
      } else if (this.colaborador.password == this.colaborador.passwordConfirm) {
        return true
      }

    },
  },
  async mounted () {
    await this.getInitialInfo()
  }
};
</script>

<style lang="scss"></style>
